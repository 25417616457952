<template>
	<div class="">
		<b-button v-b-modal.new-material-modal variant="primary"> New material</b-button>
		<b-modal
			id="new-material-modal"
			ref="newMaterialModal"
			:title="isEdit ? `Edit material` : `Add new material`"
			ok-title="Save"
			centered
			cancel-variant="outline-secondary"
			@hidden="resetModal"
		>
			<validation-observer ref="formRules">
				<b-form>
					<b-row>
						<!-- name -->
						<b-col cols="12">
							<b-form-group label="Name" label-for="name">
								<validation-provider #default="{ errors }" name="Name" rules="required">
									<b-form-textarea
										id="name"
										v-model="formData.name"
										:state="errors.length > 0 ? false : null"
										placeholder="Name"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<!-- unit -->
						<b-col cols="12">
							<b-form-group label="Unit" label-for="unit">
								<validation-provider #default="{ errors }" name="Unit" rules="required">
									<b-form-input
										id="unit"
										v-model="formData.unit"
										:state="errors.length > 0 ? false : null"
										placeholder="Unit"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>

			<template #modal-footer="{ cancel }">
				<b-button variant="outline-secondary" @click="cancel">Cancel</b-button>
				<loading-btn variant="primary" :loading="loading" @click="handleOk">Save</loading-btn>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormTextarea, BFormGroup, BForm, BRow, BCol, BButton } from 'bootstrap-vue';
import LoadingBtn from '@/components/LoadingBtn.vue';
import { required } from '@validations';
import store from '@/store';

export default {
	name: 'NewMaterial',

	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormTextarea,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BButton,
		LoadingBtn,
	},

	props: {
		data: {
			type: Object,
			default: () => ({
				name: '',
				unit: '',
			}),
		},
	},

	data: () => ({
		required,
		loading: false,
	}),

	computed: {
		formData() {
			return this.data;
		},

		isEdit() {
			return !!this.data.id;
		},
	},

	methods: {
		resetModal() {
			this.$emit('hidden');
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		async handleSubmit() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			this.loading = true;
			try {
				if (this.isEdit) {
					await store.dispatch('materials/editMaterial', { material: { ...this.formData }, id: this.formData.id });
				} else {
					await store.dispatch('materials/addMaterial', { ...this.formData });
				}

				this.loading = false;
			} catch (error) {
				console.log(error);
				this.loading = false;
			}

			// Hide the modal manually
			this.$nextTick(() => {
				// eslint-disable-next-line no-unused-expressions
				this.$refs.newMaterialModal?.toggle();
			});

			this.$emit('done');
		},
	},
};
</script>

<style lang="scss"></style>
