<template>
	<base-table ref="itemsTable" :items="fetchItems" :headers="itemsHeaders" table-top paginate>
		<template #action>
			<new-item :sub-items="subItems" :data="itemData" @done="refresh" @hidden="restFormData" />
		</template>
		<template #cell(created_at)="{ value }">
			{{ getProperDate(value) }}
		</template>
		<template #cell(sub_items)="{ value }">
			{{ value.length }}
		</template>

		<template #cell(actions)="{ item }">
			<b-button
				v-b-modal.new-item-modal
				v-b-tooltip.hover.top="'Edit'"
				variant="outline-secondary"
				class="btn-icon cursor-pointer"
				size="sm"
				@click="editItem(item)"
			>
				<feather-icon icon="Edit3Icon" size="16" />
			</b-button>
		</template>
	</base-table>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import { getProperDate } from '@/utils/helpers';
import NewItem from './new-item.vue';

export default {
	name: 'Items',

	components: { BaseTable, BButton, NewItem },

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		subItems: [],

		itemData: undefined,

		itemsHeaders: [
			{ key: 'id' },
			{ key: 'name' },
			{ key: 'unit' },
			{ key: 'sub_items' },
			{ key: 'created_at', label: 'date' },
			{ key: 'actions' },
		],
	}),

	async created() {
		this.subItems = (await store.dispatch('newItems/getSubItems')).data;
	},

	methods: {
		fetchItems(ctx, searchQuery) {
			return store.dispatch('newItems/getItems', { ...ctx, searchQuery });
		},

		restFormData() {
			this.itemData = undefined;
		},

		editItem(item) {
			this.itemData = {
				id: item.id,
				name: item.name,
				unit: item.unit,
				sub_items: item.sub_items,
			};
		},

		refresh() {
			this.$refs.itemsTable.refresh();
		},

		getProperDate,
	},
};
</script>

<style lang="scss" scoped></style>
