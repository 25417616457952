var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tabs',{attrs:{"vertical":"","pills":"","nav-class":"nav-left","nav-wrapper-class":"col-md-2 col-12","content-class":"col-12 col-md-10 mt-1 mt-md-0"}},[_c('b-tab',{attrs:{"title":"Projects"}},[_c('base-table',{ref:"table",attrs:{"headers":_vm.headers,"items":_vm.fetchProjects,"sort-by":"date","table-top":"","paginate":"","has-select":""},on:{"selectChange":_vm.selectProjects},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(item.is_setup)?_c('div',{staticClass:"text-nowrap"},[_c('b-button',{staticClass:"cursor-pointer",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){return _vm.$router.push({
									name: 'project.cfw.details',
									params: { id: item.id },
								})}}},[_c('span',{staticClass:"align-middle"},[_vm._v("View ")])])],1):_c('div',{staticClass:"text-nowrap"},[_c('b-button',{staticClass:"cursor-pointer",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.$router.push({
									name: 'project.cfw.setup',
									params: { id: item.id },
								})}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Setup ")])])],1)]}},{key:"action",fn:function(){return [_c('b-button',{staticClass:"cursor-pointer",attrs:{"variant":"primary","disabled":!_vm.selectedProjects.length},on:{"click":_vm.syncProjects}},[_vm._v(" Sync data with MIS ")])]},proxy:true}])})],1),_c('b-tab',{attrs:{"title":"Materials","lazy":""}},[_c('materials')],1),_c('b-tab',{attrs:{"title":"Main Items","lazy":""}},[_c('main-items')],1),_c('b-tab',{attrs:{"title":"Sub Items","lazy":""}},[_c('sub-items')],1),_c('b-tab',{attrs:{"title":"City Officers","lazy":""}},[_c('city-officers')],1),_c('b-tab',{attrs:{"title":"StoreKeepers","lazy":""}},[_c('store-keepers')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }