<template>
	<div>
		<base-table
			ref="table"
			:headers="headers"
			:items="fetchProjects"
			sort-by="date"
			table-top
			paginate
			has-select
			@selectChange="selectProjects"
		>
			<template #cell(actions)="{ item }">
				<div class="text-nowrap">
					<b-button
						variant="outline-secondary"
						class="cursor-pointer"
						size="sm"
						@click="
							$router.push({
								name: 'project.details',
								params: { id: item.id },
							})
						"
					>
						<span class="align-middle">View </span>
					</b-button>
				</div>
			</template>

			<template #action>
				<b-button
					variant="primary"
					class="cursor-pointer"
					:disabled="!selectedProjects.length"
					@click="syncProjects"
				>
					Sync data with MIS
				</b-button>
			</template>
		</base-table>
	</div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';

export default {
	name: 'GeneralProjects',

	components: { BaseTable, BButton },

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'name', sortable: true },
			{ key: 'sector', sortable: true },
			{ key: 'date', sortable: true },
			{ key: 'actions' },
		],

		projects: [],
		selectedProjects: [],
	}),

	methods: {
		fetchProjects(ctx, searchQuery) {
			return store.dispatch('projects/getProjects', { ...ctx, searchQuery, type: 1 });
		},

		selectProjects(val) {
			this.selectedProjects = val;
		},

		async syncProjects() {
			await store.dispatch('projects/syncProjects', this.selectedProjects);
		},
	},
};
</script>

<style lang="scss" scoped></style>
