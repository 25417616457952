<template>
	<div>
		<base-table ref="table" table-top paginate :items="fetchStoreKeepers" :headers="headers">
			<template #action>
				<new-user :user-type="14" title="Store keepers" :data="formData" @hidden="formData = undefined" @done="refresh" />
			</template>

			<template #cell(actions)="{ item }">
				<b-button
					v-b-modal.new-user-modal
					v-b-tooltip.hover.top="'Edit'"
					variant="outline-secondary"
					class="btn-icon cursor-pointer"
					size="sm"
					@click="edit(item)"
				>
					<feather-icon icon="Edit3Icon" size="16" />
				</b-button>
			</template>
		</base-table>
	</div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import NewUser from '@/components/user/new-user.vue';

export default {
	name: 'StoreKeepers',

	components: { BButton, BaseTable, NewUser },

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		formData: undefined,

		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'name', label: 'Name', sortable: true },
			{ key: 'typeName', label: 'Type', sortable: true },
			{ key: 'phone', label: 'Phone', sortable: true },
			{ key: 'actions', label: 'Actions' },
		],
	}),

	methods: {
		fetchStoreKeepers(ctx, searchQuery) {
			return store.dispatch('users/getUsers', { ...ctx, searchQuery, type: 14 });
		},

		refresh() {
			this.$refs.table.refresh();
		},

		edit(item) {
			this.formData = {
				id: item.id,
				name: item.name,
				phone: item.phone,
			};
		},
	},
};
</script>

<style lang="scss" scoped></style>
