<template>
	<div>
		<b-tabs vertical pills nav-class="nav-left" nav-wrapper-class="col-md-2 col-12" content-class="col-12 col-md-10 mt-1 mt-md-0">
			<b-tab title="Projects">
				<base-table
					ref="table"
					:headers="headers"
					:items="fetchProjects"
					sort-by="date"
					table-top
					paginate
					has-select
					@selectChange="selectProjects"
				>
					<template #cell(actions)="{ item }">
						<div v-if="item.is_setup" class="text-nowrap">
							<b-button
								variant="outline-secondary"
								class="cursor-pointer"
								size="sm"
								@click="
									$router.push({
										name: 'project.cfw.details',
										params: { id: item.id },
									})
								"
							>
								<span class="align-middle">View </span>
							</b-button>
						</div>
						<div v-else class="text-nowrap">
							<b-button
								variant="outline-primary"
								class="cursor-pointer"
								size="sm"
								@click="
									$router.push({
										name: 'project.cfw.setup',
										params: { id: item.id },
									})
								"
							>
								<span class="align-middle">Setup </span>
							</b-button>
						</div>
					</template>

					<template #action>
						<b-button variant="primary" class="cursor-pointer" :disabled="!selectedProjects.length" @click="syncProjects">
							Sync data with MIS
						</b-button>
					</template>
				</base-table>
			</b-tab>

			<b-tab title="Materials" lazy> <materials /> </b-tab>
			<b-tab title="Main Items" lazy> <main-items /> </b-tab>
			<b-tab title="Sub Items" lazy> <sub-items /> </b-tab>

			<b-tab title="City Officers" lazy><city-officers /> </b-tab>
			<b-tab title="StoreKeepers" lazy> <store-keepers /> </b-tab>
		</b-tabs>
	</div>
</template>

<script>
import { BTabs, BTab, BButton } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';

import Materials from '../cash-for-work/materials/Index.vue';
import MainItems from '../cash-for-work/items/MainItems.vue';
import SubItems from '../cash-for-work/items/SubItems.vue';

import CityOfficers from '../cash-for-work/user-types/city-officers/Index.vue';
import StoreKeepers from '../cash-for-work/user-types/store-keepers/Index.vue';

export default {
	name: 'CashForWorkProjects',

	components: {
		BTabs,
		BTab,
		BButton,
		BaseTable,
		Materials,
		MainItems,
		SubItems,
		CityOfficers,
		StoreKeepers,
	},

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'name', sortable: true },
			{ key: 'sector', sortable: true },
			{ key: 'date', sortable: true },
			{ key: 'actions' },
		],

		projects: [],
		selectedProjects: [],
	}),

	methods: {
		fetchProjects(ctx, searchQuery) {
			return store.dispatch('projects/getProjects', { ...ctx, searchQuery, type: 3 });
		},

		selectProjects(val) {
			this.selectedProjects = val;
		},

		async syncProjects() {
			await store.dispatch('projects/syncProjects', this.selectedProjects);
		},
	},
};
</script>

<style lang="scss" scoped></style>
