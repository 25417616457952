<template>
	<div class="">
		<b-button v-b-modal.new-sub-item-modal variant="primary"> New sub item </b-button>
		<b-modal
			id="new-sub-item-modal"
			ref="newSubItemModal"
			:title="isEdit ? `Edit sub item` : `Add new sub item`"
			ok-title="Save"
			centered
			cancel-variant="outline-secondary"
			@hidden="resetModal"
		>
			<validation-observer ref="formRules">
				<b-form>
					<b-row>
						<!-- name -->
						<b-col cols="12">
							<b-form-group label="Name" label-for="name">
								<validation-provider #default="{ errors }" name="Name" rules="required">
									<b-form-textarea
										id="name"
										v-model="formData.name"
										:state="errors.length > 0 ? false : null"
										placeholder="Name"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<!-- unit -->
						<b-col cols="12">
							<b-form-group label="Unit" label-for="unit">
								<validation-provider #default="{ errors }" name="Unit" rules="required">
									<b-form-input
										id="unit"
										v-model="formData.unit"
										:state="errors.length > 0 ? false : null"
										placeholder="Unit"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<!-- has materials -->
						<b-col cols="12">
							<b-form-checkbox v-model="formData.has_materials" name="check-button" switch inline>
								Has materials
							</b-form-checkbox>
						</b-col>
						<!-- materials -->
						<b-col v-if="formData.has_materials" cols="12">
							<span class="mt-1 mb-50 d-block" style="font-size: 12px">Sub item materials</span>
							<div v-if="!materialLoading" class="px-1 py-1 mt-50 border rounded" style="background-color: #f9f9f9">
								<b-form-group label="Select material">
									<v-select
										id="item"
										v-model="material_id"
										label="name"
										:options="materials"
										:reduce="(o) => o.id"
										@input="addMaterial"
									/>
								</b-form-group>
								<div v-for="item in formData.materials" :key="item.id">
									<div class="py-50 px-1 mb-50 sub-cat">
										<p class="mb-0">
											<span>{{ item.name }}</span> - <span>{{ item.unit }}</span>
										</p>
										<b-button variant="flat-danger" size="sm" class="btn-icon ml-25" @click="deleteMaterial(item.id)">
											<feather-icon icon="Trash2Icon" />
										</b-button>
									</div>
								</div>
							</div>
							<div v-else class="">
								<div class="text-center text-primary mt-3 mb-2">
									<b-spinner class="align-middle" style="width: 2rem; height: 2rem;"></b-spinner>
									<span class="d-block mt-1">Loading...</span>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>

			<template #modal-footer="{ cancel }">
				<b-button variant="outline-secondary" @click="cancel">Cancel</b-button>
				<loading-btn variant="primary" :loading="loading" @click="handleOk">Save</loading-btn>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormTextarea, BFormGroup, BForm, BSpinner, BRow, BCol, BFormCheckbox, BButton } from 'bootstrap-vue';
import LoadingBtn from '@/components/LoadingBtn.vue';
import { required } from '@validations';
import vSelect from 'vue-select';
import store from '@/store';

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormTextarea,
		BFormGroup,
		BForm,
		BSpinner,
		BRow,
		BCol,
		BFormCheckbox,
		BButton,
		LoadingBtn,
		vSelect,
	},

	props: {
		data: {
			type: Object,
			default: () => ({
				name: '',
				unit: '',
				has_materials: false,
				materials: [],
			}),
		},
	},

	data: () => ({
		required,
		loading: false,
		materialLoading: false,
		material_id: '',
		materials: [],
	}),

	computed: {
		formData() {
			return this.data;
		},

		isEdit() {
			return !!this.data.id;
		},
	},

	watch: {
		'formData.has_materials': function(value) {
			if (value && !this.materials.length) {
				this.loadMaterials();
			}
		},
	},

	methods: {
		async loadMaterials() {
			try {
				this.materialLoading = true;
				this.materials = (await store.dispatch('materials/getMaterials', true))?.data ?? [];
			} catch (err) {
				console.error(err);
			} finally {
				this.materialLoading = false;
			}
		},

		addMaterial(id) {
			this.formData.materials.push(this.materials.find((i) => i.id === id));
			this.materials = this.materials.filter((i) => i.id !== id);
			this.material_id = '';
		},

		deleteMaterial(id) {
			this.materials.push(this.formData.materials.find((i) => i.id === id));
			this.formData.materials = this.formData.materials.filter((i) => i.id !== id);
		},

		resetModal() {
			this.$emit('hidden');
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		async handleSubmit() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			this.loading = true;
			try {
				const data = {
					...this.formData,
					materials: this.formData.has_materials ? this.formData.materials.map((i) => i.id) : [],
				};

				if (this.isEdit) {
					await store.dispatch('newItems/updateSubItem', { ...data, itemId: this.formData.id });
				} else {
					await store.dispatch('newItems/addSubItem', data);
				}

				this.loading = false;
			} catch (error) {
				console.log(error);
				this.loading = false;
			}

			// Hide the modal manually
			this.$nextTick(() => {
				// eslint-disable-next-line no-unused-expressions
				this.$refs.newSubItemModal?.toggle();
			});

			this.$emit('done');
		},
	},
};
</script>

<style lang="scss" scoped>
.sub-cat {
	background-color: #fff;
	border-left: 4px solid #8bc53f;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
