<template>
	<div>
		<base-table ref="materials" :headers="headers" :items="fetchMaterials" table-top paginate>
			<template #action>
				<new-material :data="materialData" @done="refresh" @hidden="restFormData" />
			</template>
			<template #cell(actions)="{ item }">
				<b-button
					v-b-modal.new-material-modal
					v-b-tooltip.hover.top="'Edit'"
					variant="outline-secondary"
					class="btn-icon cursor-pointer"
					size="sm"
					@click="editMaterial(item)"
				>
					<feather-icon icon="Edit3Icon" size="16" />
				</b-button>
				<b-button
					v-b-tooltip.hover.top="'Delete'"
					variant="outline-danger"
					class="btn-icon cursor-pointer mx-1"
					size="sm"
					@click="deleteMaterial(item)"
				>
					<feather-icon icon="Trash2Icon" size="16" />
				</b-button>
			</template>
		</base-table>

		<confirm-dailog ref="dailog" />
	</div>
</template>

<script>
import { VBTooltip, BButton } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import ConfirmDailog from '@/components/ConfirmDailog.vue';
import store from '@/store';
import NewMaterial from './new-material.vue';

export default {
	name: 'Materials',

	components: {
		BaseTable,
		NewMaterial,
		BButton,
		ConfirmDailog,
	},

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		headers: [{ key: 'id', label: 'ID' }, { key: 'name' }, { key: 'unit' }, { key: 'actions' }],

		materialData: undefined,
	}),

	methods: {
		fetchMaterials(ctx, searchQuery) {
			return store.dispatch('materials/getMaterials', { ...ctx, searchQuery });
		},

		restFormData() {
			this.materialData = undefined;
		},

		editMaterial(material) {
			this.materialData = {
				id: material.id,
				name: material.name,
				unit: material.unit,
			};
		},

		async deleteMaterial(material) {
			const confirm = await this.$refs.dailog.open('Delete material', 'Are you sure, you want to delete?');
			if (confirm) {
				await store.dispatch('materials/deleteMaterial', material.id);
				this.refresh();
			}
		},

		refresh() {
			this.$refs.materials.refresh();
		},
	},
};
</script>

<style lang="scss" scoped></style>
