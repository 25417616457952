<template>
	<div class="">
		<b-button v-b-modal.new-item-modal variant="primary"> New item </b-button>
		<b-modal
			id="new-item-modal"
			ref="newItemModal"
			:title="isEdit ? `Edit item` : `Add new item`"
			ok-title="Save"
			centered
			no-close-on-backdrop
			cancel-variant="outline-secondary"
			@hidden="resetModal"
		>
			<validation-observer ref="formRules">
				<b-form>
					<b-row>
						<!-- name -->
						<b-col cols="12">
							<b-form-group label="Name" label-for="name">
								<validation-provider #default="{ errors }" name="Name" rules="required">
									<b-form-textarea
										id="name"
										v-model="formData.name"
										:state="errors.length > 0 ? false : null"
										placeholder="Name"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- unit -->
						<b-col cols="12">
							<b-form-group label="Unit" label-for="unit">
								<validation-provider #default="{ errors }" name="Unit" rules="required">
									<b-form-input
										id="unit"
										v-model="formData.unit"
										:state="errors.length > 0 ? false : null"
										placeholder="Unit"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- sub Items -->
						<b-col>
							<span class="mb-50 d-block" style="font-size: 12px">Sub items</span>
							<div class="px-1 py-1 mt-50 border rounded" style="background-color: #f9f9f9">
								<b-form-group label="Select sub item">
									<v-select
										id="item"
										v-model="subItem"
										label="name"
										:options="subItems"
										:reduce="(o) => o.id"
										@input="addSubItem"
									/>
								</b-form-group>
								<div v-for="item in formData.sub_items" :key="item.id">
									<div class="py-50 px-1 mb-50 sub-cat">
										<p class="mb-0">
											<span>{{ item.name }}</span> - <span>{{ item.unit }}</span>
										</p>
										<b-button variant="flat-danger" size="sm" class="btn-icon ml-25" @click="deleteSubItem(item.id)">
											<feather-icon icon="Trash2Icon" />
										</b-button>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>

			<template #modal-footer="{ cancel }">
				<b-button variant="outline-secondary" @click="cancel">Cancel</b-button>
				<loading-btn variant="primary" :loading="loading" @click="handleOk">Save</loading-btn>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormTextarea, BFormGroup, BForm, BRow, BCol, BButton } from 'bootstrap-vue';
import LoadingBtn from '@/components/LoadingBtn.vue';
import { required } from '@validations';
import vSelect from 'vue-select';
import store from '@/store';

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormTextarea,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BButton,
		vSelect,
		LoadingBtn,
	},

	props: {
		data: {
			type: Object,
			default: () => ({
				name: '',
				unit: '',
				sub_items: [],
			}),
		},
		subItems: {
			type: Array,
			default: () => [],
		},
	},

	data: () => ({
		required,
		subItem: '',
		loading: false,
	}),

	computed: {
		formData() {
			return this.data;
		},

		isEdit() {
			return !!this.data.id;
		},
	},

	methods: {
		resetModal() {
			this.$emit('hidden');
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		addSubItem(subItem) {
			this.formData.sub_items.push(this.subItems.find((i) => i.id === subItem));
			this.subItem = '';
		},

		deleteSubItem(id) {
			this.formData.sub_items = this.formData.sub_items.filter((subItem) => subItem.id !== id);
		},

		async handleSubmit() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			const data = {
				name: this.formData.name,
				unit: this.formData.unit,
				sub_items: this.formData.sub_items.map((i) => i.id),
			};

			this.loading = true;
			try {
				if (this.isEdit) {
					await store.dispatch('newItems/updateItem', { ...data, itemId: this.formData.id });
				} else {
					await store.dispatch('newItems/addItem', data);
				}
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.loading = false;
			}

			// Hide the modal manually
			this.$nextTick(() => {
				this.$refs.newItemModal.toggle();
			});

			this.$emit('done');
		},
	},
};
</script>

<style lang="scss" scoped>
.sub-cat {
	background-color: #fff;
	border-left: 4px solid #8bc53f;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
