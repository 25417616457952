<template>
	<div class="">
		<b-tabs pills lazy align="right">
			<b-tab title="General">
				<general-projects />
			</b-tab>

			<b-tab title="Social contracting">
				<social-contracting-projects />
			</b-tab>

			<b-tab title="Emergency employment program">
				<cash-for-work-projects />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import GeneralProjects from './by-type/GeneralProjects.vue';
import SocialContractingProjects from './by-type/SocialContractingProjects.vue';
import CashForWorkProjects from './by-type/CashForWorkProjects.vue';

export default {
	name: 'ProjectIndex',

	components: {
		BTabs,
		BTab,
		GeneralProjects,
		SocialContractingProjects,
		CashForWorkProjects,
	},
};
</script>

<style lang="scss" scoped></style>
