<template>
	<base-table ref="subItemsTable" :items="fetchSubItems" :headers="subItemsHeaders" table-top paginate>
		<template #action>
			<new-sub-item :data="subItemData" @done="refresh" @hidden="restFormData" />
		</template>
		<template #cell(created_at)="{ value }">
			{{ getProperDate(value) }}
		</template>
		<template #cell(materials)="{ value }">
			{{ value.length }}
		</template>

		<template #cell(actions)="{ item }">
			<b-button
				v-b-modal.new-sub-item-modal
				v-b-tooltip.hover.top="'Edit'"
				variant="outline-secondary"
				class="btn-icon cursor-pointer"
				size="sm"
				@click="editSubItem(item)"
			>
				<feather-icon icon="Edit3Icon" size="16" />
			</b-button>
		</template>
	</base-table>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import { getProperDate } from '@/utils/helpers';
import NewSubItem from './new-sub-item.vue';

export default {
	name: 'Items',

	components: { BaseTable, BButton, NewSubItem },

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		subItemData: undefined,

		subItemsHeaders: [
			{ key: 'id' },
			{ key: 'name' },
			{ key: 'unit' },
			{ key: 'materials' },
			{ key: 'created_at', label: 'date' },
			{ key: 'actions' },
		],
	}),
	methods: {
		fetchSubItems(ctx, searchQuery) {
			return store.dispatch('newItems/getSubItems', { ...ctx, searchQuery });
		},

		restFormData() {
			this.subItemData = undefined;
		},

		editSubItem(subItem) {
			this.subItemData = {
				id: subItem.id,
				name: subItem.name,
				unit: subItem.unit,
				item_id: subItem.item_id,
				has_materials: subItem.has_materials,
				materials: subItem.materials,
			};
		},

		refresh() {
			this.$refs.subItemsTable.refresh();
		},

		getProperDate,
	},
};
</script>

<style lang="scss" scoped></style>
